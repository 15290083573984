

input{
    color: black;
}

a{
    text-decoration: none;
    color: #1890FF;
}

hr{
    width: 100%;
    margin-top: 22px;
}

.auth{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: fit-content;
}
.form{
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: fit-content;
}

.tabs{
    display: flex;
    width: inherit;
    justify-content: space-between;
}
.textInputs{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 108px;
}

.checkboxCont{
    display: inline-block;
}
.inlineCont{
    margin-top: 23px;
    margin-bottom: 23px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.error{
    color:red;
    cursor: pointer;
}
.checkboxCont label{
    margin-left: 14px;
}

.link:hover{
    text-decoration: underline;
}