.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 360px;

    text-align: center;
}
.text{
margin-bottom: 36px;
}
.inlineCont{
    display: flex;
    justify-content: flex-end;
}
.checkboxCont{
    margin-top: 62px;
    display: flex;
    align-items: baseline;
    width: 100%;
}
.codeLink{
    margin-top: 22px;
    margin-bottom: 22px;
}
.error{
    margin-top: 22px;
    margin-bottom: 22px;
    color: red;
    cursor:pointer;
}
.agreementCont{
    text-align: left;
    margin-left: 15px;
}
.agreementLinks{
    display: flex;
    flex-direction: column;
    margin-top: 28px;
}
.agreementLink{
    color: #757575;
    text-decoration: underline;
}